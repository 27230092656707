import { useEffect, useState, useContext, } from 'react';
import Search from '../assets/search_icon.svg';
import WhatsApp from '../assets/svg/WhatsApp.svg';
import ShoppingCart from '../assets/svg/shopping_cart.svg'
import CompanyLogo from "../assets/header_logo.png";
import * as language from '../constants/languages';
import { getSupportData } from '../services/services';
import { AuthContext } from '../pages/RootPage';
import '../styles/HeaderLarge.css'
import { useNavigate, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Badge from '@mui/material/Badge';
import DropDownCart from './DropDownCart';
import { ReactSVG } from 'react-svg';
export default function HeaderLarge({ opensnack }) {
    const location = useLocation()
    const navigate = useNavigate()
    const [badgeCount, setbadgeCount] = useState(0)
    const { toggleDrawer } = useContext(AuthContext);
    const [whatsAppNo, setwhatsAppNo] = useState("")
    const [supportNumber, setsupportNumber] = useState("")
    const [searchname, setsearchname] = useState("")
    const [Cart, setCart] = useState()
    const [currencySymbol, setcurrencySymbol] = useState()
    const [isCartVisible, setisCartVisible] = useState(false)
    const [isHeaderSticky, setisHeaderSticky] = useState(false)
    const [logo, setlogo] = useState()
    useEffect(() => {
        getCompanyInfo();
        getSupportInfoData();
        getCartCount();
        window.addEventListener('productQtyChange', getCartCount)
        window.addEventListener('scroll', scrollEvent)

        return () => {
            window.removeEventListener('productQtyChange', getCartCount)
            window.removeEventListener('scroll', scrollEvent)

        }
    }, [])

    const scrollEvent = () => {
        setisHeaderSticky(window.scrollY >200)
    }
    // get cart count
    const getCartCount = () => {

        const cart = localStorage.getItem('cartItems');

        if (!cart) {
            setbadgeCount(0)
            setCart(undefined)
        } else {

            const cartItems = JSON.parse(cart);
            setCart(cartItems)
            setbadgeCount(cartItems.customerCartItems.length)

        }


    }

    //get sotre name
    async function getCompanyInfo() {

        const value = localStorage.getItem('companyInfo')

        if (value === null || value === undefined) {
            setwhatsAppNo("")
            setcurrencySymbol("")

        } else {
            let companyInfo = JSON.parse(value);
            const Logo = companyInfo.logo !== "" ? companyInfo.logo : CompanyLogo;
            setlogo(Logo)
            setwhatsAppNo(companyInfo.whatsAppNo)
            setcurrencySymbol(companyInfo.currencySymbol)

        }

    }

    async function getSupportInfoData() {

        await getSupportData(language.companyidValue).then((supportData) => {
            if (supportData.status === 'success') {
                setsupportNumber(supportData.data.contactNos)
            }
        })
    }

    async function openWhatsapp() {
        if (isMobile) {
            window.open('whatsapp://send?phone=' + whatsAppNo)
        }
        else {
            window.open(`https://web.whatsapp.com/send?phone=${whatsAppNo}`)
        }
    }

    async function callNow() {
        window.open(`tel:${supportNumber}`)
    }
    async function searchProduct() {
        if (searchname.trim().length == 0) {
            opensnack('Please enter a search term')
        }
        else {
            navigate(`/Search/${searchname}`)
        }

    }

    function keydown(e) {
        if (e.key === "Enter") {
            searchProduct()
        }
    }

    const setVisibility = (value) => {
        setisCartVisible(value)
    }

    return (

        <div className='HeaderLarge'>
            {/* <div className="HeaderBgLarge">
                <div className="CallNowLarge" onClick={() => callNow()}>
                    <p style={{ margin: 0, color: "#fff" }}>Call Now</p>
                </div>
            </div> */}
            <div className={`HeaderMenuLarge ${isHeaderSticky
                    ? "HeaderMenuLarge-onscroll"
                    : "HeaderMenuLarge-withoutScroll"
                }`}>
                <div className='headerLogoLarge'>
                {CompanyLogo&& <img src={CompanyLogo} height='auto' width={80} />}
                </div>
                <div className='NavigationMenu'>
                    <div className='NavigationMenuTitleContainer' onClick={() => navigate('/')}>
                        <p className='NavigationMenuTitle' style={{ color: location.pathname === '/' && '#f79723' }}>Home</p>
                        <div style={{ height: 2, backgroundColor: '#f79723', visibility: location.pathname === '/' ? 'visible' : 'hidden' }} />
                    </div>
                    <div className='NavigationMenuTitleContainer' onClick={() => navigate('/Categories')}>
                        <p className='NavigationMenuTitle' style={{ color: (location.pathname === '/Categories' || location.pathname === '/SubCategories') && '#f79723' }}>Categories</p>
                        <div style={{ height: 2, backgroundColor: '#f79723', visibility: location.pathname === '/Categories' || location.pathname === '/SubCategories' ? 'visible' : 'hidden' }} />
                    </div>
                    <div className='NavigationMenuTitleContainer' onClick={() => navigate('/Offers')}>
                        <p className='NavigationMenuTitle' style={{ color: location.pathname === '/Offers' && '#f79723' }}>Offers</p>
                        <div style={{ height: 2, backgroundColor: '#f79723', visibility: location.pathname === '/Offers' ? 'visible' : 'hidden' }} />
                    </div>
                    <div className='NavigationMenuTitleContainer' onClick={() => navigate('/About')}>
                        <p className='NavigationMenuTitle' style={{ color: location.pathname === '/About' && '#f79723' }}>About Us</p>
                        <div style={{ height: 2, backgroundColor: '#f79723', visibility: location.pathname === '/About' ? 'visible' : 'hidden' }} />
                    </div>
                </div>
                <div style={{ flexWrap: 'nowrap', display: 'flex',  }}>
                    <input type="text"
                        placeholder={language.searchLabel}
                        className='SearchFieldLarge'
                        autoCapitalize={'none'}
                        onChange={(event) => setsearchname(event.target.value)}
                        // onSubmitEditing={() => navigation.navigate('SearchScreen', { name: searchname })}
                        inputMode='search'
                        onKeyDown={(e) => keydown(e)}
                    />
                    <div className='SearchIconLarge'>
                        <img src={Search} onClick={() => searchProduct()} />
                    </div>

                    <div className='CartIconLarge' onMouseEnter={() => setisCartVisible(true)} onMouseLeave={() => setisCartVisible(false)} onClick={()=>setisCartVisible(!isCartVisible)}> 
                        <Badge badgeContent={badgeCount} color="primary">
                            <img src={ShoppingCart} />
                        </Badge>
                        <DropDownCart cart={Cart} currencySymbol={currencySymbol} isVisible={isCartVisible} setVisibility={setVisibility} />

                    </div>
                </div>
            </div>

            {/* <div className='fab' onClick={() => openWhatsapp()}>
                <ReactSVG src={WhatsApp} />
            </div> */}

        </div>

    )

}